(function($, window, document, undefined) {
  'use strict';

  var $html = $('html');
  var $shortcuts = $('.shortcut');

  $shortcuts.filter('[href="#navigation"]').on('click', function(e) {
    e.preventDefault();
    var $shortcut = $(this);
    $html.toggleClass('navigation-is-active');
    $shortcut.toggleClass('is-active');
    $html.on('click', function(e) {
      if(0 === $(e.target).closest('#navigation').length &&  0 === $(e.target).closest('#shortcuts').length) {
        $html.removeClass('navigation-is-active');
        $shortcut.removeClass('is-active');
      }
    });
  });

})(window.jQuery, window, document);
