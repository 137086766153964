(function($, window, document, undefined) {
  'use strict';

  var $carousel = $('.teaser-carousel .teaser-items');

  $carousel.owlCarousel({
    itemsCustom: [[0, 1], [400, 2], [750, 3], [1000, 4]]
  });

  var owl = $carousel.data('owlCarousel');

  $(document.documentElement).keyup(function(event) {
    if (37 === event.keyCode) {
        owl.prev();
    } else if (39 === event.keyCode) {
        owl.next();
    }
});

})(window.jQuery, window, document);
